/* ------- MODERN ERA ------- */
@font-face {
  font-family: "Modern Era"; font-weight: 300;
  src: url("assets/fonts/Modern_Era/Modern Era Light OTF/ModernEra-Light.otf");
}
@font-face {
  font-family: "Modern Era"; font-weight: 400;
  src: url("assets/fonts/Modern_Era/Modern Era Regular OTF/ModernEra-Regular.otf");
}
@font-face {
  font-family: "Modern Era"; font-weight: 500;
  src: url("assets/fonts/Modern_Era/Modern Era Medium OTF/ModernEra-Medium.otf");
}
@font-face {
  font-family: "Modern Era"; font-weight: 600;
  src: url("assets/fonts/Modern_Era/Modern Era Bold OTF/ModernEra-Bold.otf");
}
@font-face {
  font-family: "Modern Era"; font-weight: 700;
  src: url("assets/fonts/Modern_Era/Modern Era Bold OTF/ModernEra-Bold.otf");
}
