/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "constants";
@import "fonts";

.main-button {
  width: 100%;
  height: 40px;
  background: $text-color-primary;
  color: #ffffff;
  border-radius: 10px;
  padding: 8px 16px;
  text-transform: uppercase;
  font-family: $main-font;
  font-size: 16px;
  font-weight: bold;
}

.slot-container {
  border: 1px dashed $primary-color;
  border-radius: 24px;
  font-family: var(--main-font);
  font-weight: 500;
  width: 72px;
  text-align: center;

  &:hover {
    cursor: pointer;
    background-color: $primary-color-tint;
  }
}

.active-slot {
  background-color: $secondary-color;
  color: $primary-color;
}

.cal-cell-top {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.cal-month-view .cal-day-number {
  margin-top: 0 !important;
  margin-right: 0 !important;
  float: none;
  margin-bottom: 0 !important;
}


::ng-deep {
  .cal-cell-top {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .cal-month-view .cal-day-number {
    margin-top: 0 !important;
    margin-right: 0 !important;
    float: none;
    margin-bottom: 0 !important;
  }
}

.tutorialTooltip {
  $tooltipColor: $primary-color;

  background-color: $tooltipColor !important;
  border: none !important;
  color: #ffffff !important;

  .introjs-arrow {
    border-bottom-color: $tooltipColor !important;
  }

  .introjs-skipbutton {
    color: #ffffff;

    &:hover { color: #ffffff; }
  }

  .introjs-tooltipbuttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .introjs-button {
      background: transparent;
      border: none;
      text-shadow: none;
      color: #ffffff;

      i {
        font-family: "Font Awesome 5 Free";
      }
    }

    .introjs-disabled {
      opacity: 0.5;
    }
  }

  .introjs-tooltiptext {
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
  }

  .introjs-bullets{
    ul {
      li {
        a {
          background: rgba(255, 255, 255, 0.5) !important;
        }

        a.active {
          background: #ffffff !important;
        }
      }
    }
  }
}

.tutorialHighlight {
  box-shadow: rgb(65 38 119 / 80%) 0px 0px 1px 2px, rgb(65 38 119 / 50%) 0px 0px 0px 5000px !important;
}

.brand-color {
  color: $primary-color;
}
